import * as React from "react";
import Layout from "../../../../components/layout/layout";
import { ProductPageProps } from "../../../../typings/page.types";
import { graphql } from "gatsby";
import { Breadcrumb } from "../../../../components/breadcrumb/breadcrumb";
import Container from "../../../../components/container/container";
import * as styles from "./styles.module.scss";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import * as productHeaderStyles from "../../../../components/product-header/product-header.module.scss";
import { Level } from "react-accessible-headings";
import Heading from "../../../../components/basic-components/heading/heading";
import BisolduoSpreyLogo from "../../../../images/bisolduo-sprey-logo.svg";
import BisolduoSurupLogo from "../../../../images/bisolduo-surup-logo.svg";
import BisolviralSpreyLogo from "../../../../images/bisolviral-logo.svg";

const PricePage: React.FC<ProductPageProps> = ({
  data: { productsJson },
  location,
}: ProductPageProps) => {
  var title = ''
  if(productsJson.slug == 'bisolviral-sprey'){
    title = 'Bisolviral Ağız Spreyi Fiyatı | Bisolviral'
  }else{
    title = productsJson.slug !== "bisolnatur" ? "Bisolduo Şurup Fiyatı & Bisolduo Action Şurup Fiyatı" : "Bisolnatur Öksürük Şurubu Fiyatı";
  }
  var description = ''
  if(productsJson.slug == 'bisolviral-sprey'){
    title = 'Bisolviral Ağız Spreyi Fiyatı | Bisolviral';
    description = "Bisolviral soğuk algınlığı virüslerini hapseder, etkisiz hale getirir ve sizi korur. Bisolviral tavsiye edilen satış fiyatına göz atın."
  }else{
    description = productsJson.slug !== "bisolnatur"
        ? "Bisolduo şurup ve Bisolduo Action Şurup'un tavsiye edilen satış fiyatlarını incelemek için Bisolduo Fiyat sayfamızı ziyaret edebilirsiniz."
        : "Doğal içerikli öksürük şurubu Bisolnatur'un tavsiye edilen satış fiyatlarını incelemek için Bisolnatur Fiyat sayfamızı ziyaret edebilirsiniz.";
  }

  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title,
        description,
        noDefaultTitle: productsJson.slug !== "bisolnatur",
      }}
    >
      <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Ürünler","item": "https://www.bisolnatur.com.tr/urunler/"},
                  {"@type": "ListItem","position": 3,"name": "${productsJson.title}","item": "https://www.bisolnatur.com.tr/urunler/${productsJson.slug}/"},
                  {"@type": "ListItem","position": 4,"name": "Fiyatlar","item": "https://www.bisolnatur.com.tr/urunler/${productsJson.slug}/fiyat/"}
                ]
            }
          `}
      </script>
      <Breadcrumb pathname={location.pathname} />
      <Container fullWidth className="bg-orange lighten2">
        <Container>
          <div className={classNames("flexbox", styles.multiHolder)}>
            <div
              className={classNames(
                "flexbox",
                productHeaderStyles.component,
                productHeaderStyles[productsJson.className],
                styles[productsJson.className],
                styles.holder
              )}
            >
              <GatsbyImage
                className={classNames(productHeaderStyles.image, styles.overpositionimage)}
                loading="lazy"
                image={productsJson.className === "bisolnatur" ? productsJson.headerImage.childImageSharp.gatsbyImageData : productsJson.image.childImageSharp.gatsbyImageData}
                alt={productsJson.title + " ürün görseli"}
              />
              <img
                className={classNames(productHeaderStyles.logo, styles.logo)}
                src={productsJson.logo.publicURL}
                alt={productsJson.title + "logo"}
                width="130"
                height="46"
              />
              <ul>
                {productsJson.properties.map((property, i) => (
                  <li key={i} className="blue">
                    {property}
                  </li>
                ))}
              </ul>
              <Heading className="extra-bold">
                Tavsiye edilen satış fiyatı<br />
                <span className="green bold">{productsJson.price}</span>
              </Heading>
            </div>
            {productsJson.className === "bisolduoSurup" && (
              <div
                className={classNames(
                  "flexbox",
                  productHeaderStyles.component,
                  productHeaderStyles.bisolduoSprey,
                  styles.bisolduoSprey,
                  styles.holder
                )}
              >
                <StaticImage
                  className={classNames(
                    productHeaderStyles.image,
                    styles.image
                  )}
                  loading="lazy"
                  src="../../../../images/product-bar-bisolduo-sprey.png"
                  alt={"Bisolduo Sprey ürün görseli"}
                />
                <img
                  style={{ width: "130", height: "46" }}
                  className={classNames(productHeaderStyles.logo, styles.logo)}
                  src={BisolduoSpreyLogo}
                  alt="Bisolduo Şurup logo"
                  width="130"
                  height="46"
                />
                <ul>
                  <li className="blue">Öksürüğü rahatlatır</li>
                  <li className="blue">Boğaz ağrısını azaltır</li>
                  <li className="blue">
                    Semptomların kötüleşmesini önlemek için anında etki eder
                  </li>
                </ul>
                <Level>
                  <Heading className="extra-bold">
                    Tavsiye edilen satış fiyatı<br />
                    <span className="green bold">140,55 TL</span>
                  </Heading>
                </Level>
              </div>
            )}
            {productsJson.className === "bisolduoSprey" && (
              <div
                className={classNames(
                  "flexbox",
                  productHeaderStyles.component,
                  productHeaderStyles.bisolduoSurup,
                  styles.bisolduoSurup,
                  styles.holder
                )}
              >
                <StaticImage
                  className={classNames(productHeaderStyles.image)}
                  loading="lazy"
                  src="../../../../images/product-bar-bisolduo-surup.png"
                  alt={"Bisolduo Şurup ürün görseli"}
                />
                <img
                  className={classNames(productHeaderStyles.logo, styles.logo)}
                  src={BisolduoSurupLogo}
                  alt="Bisolduo Sprey logo"
                  width="130"
                  height="46"
                />
                <ul>
                  <li className="blue">Anında yatıştırıcı etki</li>
                  <li className="blue">Kuru öksürüğü rahatlatır</li>
                  <li className="blue">Boğaz ağrısını yatıştırır</li>
                </ul>
                <Level>
                  <Heading className="extra-bold">
                    Tavsiye edilen satış fiyatı<br />
                    <span className="green bold">166,90 TL</span>
                  </Heading>
                </Level>
              </div>
            )}
          </div>
        </Container>
      </Container>
    </Layout>
  );
};
export default PricePage;

export const query = graphql`
  query ($id: String!) {
    productsJson(id: { eq: $id }) {
      className
      logo {
        publicURL
      }
      properties
      image {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [360, 768, 1200]
            placeholder: NONE
          )
        }
      }
      headerImage {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [360, 768, 1200]
            placeholder: NONE
          )
        }
      }
      seoDescription
      seoTitle
      slug
      price
    }
  }
`;
